import _quill from "quill";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
!function (t, e) {
  exports = e(_quill);
}(window, function (t) {
  return function (t) {
    var e = {};

    function i(n) {
      if (e[n]) return e[n].exports;
      var r = e[n] = {
        i: n,
        l: !1,
        exports: {}
      };
      return t[n].call(r.exports, r, r.exports, i), r.l = !0, r.exports;
    }

    return i.m = t, i.c = e, i.d = function (t, e, n) {
      i.o(t, e) || Object.defineProperty(t, e, {
        enumerable: !0,
        get: n
      });
    }, i.r = function (t) {
      "undefined" != typeof Symbol && Symbol.toStringTag && Object.defineProperty(t, Symbol.toStringTag, {
        value: "Module"
      }), Object.defineProperty(t, "__esModule", {
        value: !0
      });
    }, i.t = function (t, e) {
      if (1 & e && (t = i(t)), 8 & e) return t;
      if (4 & e && "object" == typeof t && t && t.__esModule) return t;
      var n = Object.create(null);
      if (i.r(n), Object.defineProperty(n, "default", {
        enumerable: !0,
        value: t
      }), 2 & e && "string" != typeof t) for (var r in t) i.d(n, r, function (e) {
        return t[e];
      }.bind(null, r));
      return n;
    }, i.n = function (t) {
      var e = t && t.__esModule ? function () {
        return t.default;
      } : function () {
        return t;
      };
      return i.d(e, "a", e), e;
    }, i.o = function (t, e) {
      return Object.prototype.hasOwnProperty.call(t, e);
    }, i.p = "", i(i.s = 1);
  }([function (e, i) {
    e.exports = t;
  }, function (t, e, i) {
    "use strict";

    i.r(e), i.d(e, "default", function () {
      return o;
    });
    var n = i(0),
        r = i.n(n);
    const l = r.a.import("core/module");

    class o extends l {
      constructor(t, e) {
        super(t, e), (this || _global).htmlField = s(e.htmlField, t), (this || _global).deltaField = s(e.deltaField, t), (this || _global).textField = s(e.textField, t), (this || _global).form = (this || _global).htmlField ? (this || _global).htmlField.form : (this || _global).deltaField ? (this || _global).deltaField.form : !!(this || _global).textField && (this || _global).textField.form, (this || _global).form && ((this || _global).form.addEventListener("submit", (this || _global).update.bind(this || _global), !0), (this || _global).options.submitKey && (this || _global).quill.keyboard.addBinding((this || _global).options.submitKey, (this || _global).submit.bind(this || _global))), (this || _global).options.updateOnBlur && ((this || _global).quill.on(r.a.events.SELECTION_CHANGE, (t, e, i) => {
          t || (this || _global).update.call(this || _global);
        }), (this || _global).form && (this || _global).form.addEventListener("click", (this || _global).update.bind(this || _global), !0)), (this || _global).options.updateOnChange && (this || _global).quill.on(r.a.events.EDITOR_CHANGE, (t, e, i, n) => {
          t === r.a.events.TEXT_CHANGE && (this || _global).update.call(this || _global);
        }), this.update();
      }

      update() {
        (this || _global).deltaField && ((this || _global).deltaField.value = JSON.stringify((this || _global).quill.getContents())), (this || _global).htmlField && ((this || _global).htmlField.value = (this || _global).quill.root.innerHTML), (this || _global).textField && ((this || _global).textField.value = (this || _global).quill.root.innerText);
      }

      submit() {
        (this || _global).update.call(this || _global), (this || _global).form && (this || _global).form.submit();
      }

    }

    function s(t, e) {
      if (t instanceof HTMLInputElement) return t;

      if ("string" == typeof t) {
        var i = document.createElement("input");
        return i.type = "hidden", i.name = t, i.classList.add("ql-form-input"), i.classList.add("ql-form-" + t), e.addContainer(i), i;
      }

      if (!1 === t) return !1;
      throw new TypeError("Expected HTMLInputElement or string: " + t);
    }

    o.DEFAULTS = {
      htmlField: "html",
      deltaField: "delta",
      textField: "text",
      submitKey: {
        key: "S",
        shortKey: !0
      },
      updateOnBlur: !0,
      updateOnChange: !1
    }, r.a.register("modules/form", o);
  }]);
});
export default exports;
export const QuillForm = exports.QuillForm;